import React from 'react'


const Alert = (props) => {

  const toggle1 = ()=>{
    // alert("Finally!");

        if ( document.getElementById('alert-1').classList.contains('hidden') ){
            document.getElementById('alert-1').classList.remove('hidden');
        }
        else{
            document.getElementById('alert-1').classList.add('hidden');
        }
    }
  
  return (
    
       props.alert &&  <div id="alert-1" className="flex p-4 mb-4 bg-green-100 rounded-lg dark:bg-green-200" role="alert">
  <div className="ml-3 text-sm font-medium text-green-700 dark:text-green-800">
  <span class="font-medium">Success!</span> {props.alert.msg}
  </div>
  <button type="button" onClick={toggle1} className="ml-auto -mx-1.5 -my-1.5 bg-green-100 text-green-500 rounded-lg focus:ring-2 focus:ring-green-400 p-1.5 hover:bg-green-200 inline-flex h-8 w-8 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300" data-dismiss-target="#alert-1" aria-label="Close">
    <span className="sr-only">Close</span>
    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
  </button>
</div>
   
  )
}

export default Alert