import React, {useState} from 'react';
import emailjs from 'emailjs-com'
import Alert from './Alert';


const Contact = () => {

  const [alert, setAlert] = useState(null);

    const showAlert = (message)=>{
        setAlert({
          msg: message
        });
    }

    
    function sendEmail(e){
        e.preventDefault();

        emailjs.sendForm('service_68664f6', 'template_2br94ed',e.target,"NTG2ooNapq8Xbe4Uo").then(res=>{
            console.log(res);
            showAlert("Your form has been submitted!");
        }).catch(err=>{
            console.log(err);
        })
        e.target.reset();
    }
    


    
  
  return (
    <>
        <Alert alert={alert}/>
  <div className="max-w-screen-xl mt-16 px-4 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-8 lg:px-12 xl:px-26 py-16 mx-auto bg-gray-100 text-gray-900 rounded-lg shadow-lg">

    <div className='flex flex-col justify-between'>
        <div>
        <h2 className='text-center text-3xl font-semibold text-gray-800 md:text-4xl'>Contact Me</h2>
        <p className='justify-center py-4 px-4 text-md lg:text-md leading-tight'>
        Feel free to ask anything. If you have any suggestions or queries, please let me know. Your suggestions are highly appreciated. I appreciate your time and I will try my best to reply to every single message posted here! Keep dropping your priceless opinions.</p>
        </div>
        
    </div>

      
    <form onSubmit={sendEmail}>
      <label className="block mb-6">
        <span className="text-gray-700">Your name</span>
        <input
          type="text"
          name="name"
          className="
            block
            w-full
            mt-1
            border-gray-300
            rounded-md
            shadow-sm
            focus:border-indigo-300
            focus:ring
            focus:ring-indigo-200
            focus:ring-opacity-50
          "
          placeholder="Joe Bloggs"
        />
      </label>
      <label className="block mb-6">
        <span className="text-gray-700">Email address</span>
        <input
          name="email"
          type="email"
          className="
            block
            w-full
            mt-1
            border-gray-300
            rounded-md
            shadow-sm
            focus:border-indigo-300
            focus:ring
            focus:ring-indigo-200
            focus:ring-opacity-50
          "
          placeholder="joe.bloggs@example.com"
          required
        />
      </label>
      <label className="block mb-6">
        <span className="text-gray-700">Message</span>
        <textarea
          name="message"
          className="
            block
            w-full
            mt-1
            border-gray-300
            rounded-md
            shadow-sm
            focus:border-indigo-300
            focus:ring
            focus:ring-indigo-200
            focus:ring-opacity-50
          "
          rows="3"
          placeholder="Tell us what you're thinking about..."
        ></textarea>
      </label>
      <div className="mb-6">
        <button
          type="submit"
          className="
          my-3 inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-sky-700 rounded-lg hover:bg-sky-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
          "
        >
          Submit
        </button>
      </div>
      <div>
        
      </div>
    </form>
  </div>

    </>
  )
}

export default Contact