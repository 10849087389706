import React from 'react'
import image from './profilepic.png';

import {
  Link
  } from "react-router-dom";
  
const Home = () => {
  return (
    <>
    
    <div className="w-full mt-5">
      <div className="flex flex-col justify-center max-w-xs mx-auto bg-white shadow-xl rounded-xl p-5">
        <div>
          <img src={image} alt="profile photo" className="w-32 mx-auto rounded-full drop-shadow-sm" />
        </div>
        <div className="text-center mt-5">
            <p className="text-gray-900 text-2xl sm:text-4xl font-bold">
              Prerak Mehta
            </p>
            <p className="text-gray-600 text-base sm:text-xl pt-2 pb-4 px-5 w-auto inline-block border-b-2">
              Course Instructor | Coding Enthusiast
            </p>
            <div className="flex align-center justify-center mt-4">
            <Link to={{ pathname:"https://twitter.com/prerakdmehta"}} target="_blank_"><i className="fab fa-twitter hover:text-cyan-500 px-2 text-3xl" style={{transition: "color 0.4s"}}></i></Link>

<Link className='github' to={{ pathname:"https://github.com/prerakmehtagit"}} target="_blank_"
  ><i className="fab fa-github hover:text-orange-500 px-2 text-3xl" style={{transition: "color 0.4s"}}></i
></Link>
{/* <Link
  to={{ pathname:"https://telegram.com"}}
  target="_blank_"><i className="fab fa-telegram hover:text-cyan-600 px-2 text-3xl" style={{transition: "color 0.4s"}}></i></Link> */}
<Link
  to={{ pathname:"https://www.linkedin.com/in/prerak-mehta-51b282214/"}}
  target="_blank_"
  ><i className="fab fa-linkedin hover:text-cyan-600 px-2 text-3xl" style={{transition: "color 0.4s"}}></i></Link>
{/* <Link
  to={{ pathname:"https://discord.com"}}
  target="_blank_"
  ><i className="fab fa-discord hover:text-violet-700	px-2 text-3xl" style={{transition: "color 0.4s"}}></i></Link> */}
            </div>
        </div>
        
      </div>
    </div>

    </>
  )
}

export default Home