import React from 'react'

import {
  Link
  } from "react-router-dom";
  
const Footer = () => {
  return (
    <div>
      <footer className="text-2xl text-center w-full flex flex-col pt-5 bg-gray-200">
      <h1 className='text-gray-900 text-2xl sm:text-3xl font-bold'>
        Stay Connected
      </h1>
      <div className="social_media_icons mt-1">
        <Link to={{ pathname:"https://twitter.com/prerakdmehta"}} target="_blank_"><i className="fab fa-twitter hover:text-cyan-500 px-2 text-3xl mt-3" style={{transition: "color 0.4s"}}></i></Link>

        <Link className='github' to={{ pathname:"https://github.com/prerakmehtagit"}} target="_blank_"
          ><i className="fab fa-github hover:text-orange-500 px-2 text-3xl mt-3" style={{transition: "color 0.4s"}}></i
        ></Link>
        {/* <Link
          to={{ pathname:"https://telegram.com"}}
          target="_blank_"><i className="fab fa-telegram hover:text-cyan-600 px-2 text-3xl mt-3" style={{transition: "color 0.4s"}}></i></Link> */}
        <Link
          to={{ pathname:"https://www.linkedin.com/in/prerak-mehta-51b282214/"}}
          target="_blank_"
          ><i className="fab fa-linkedin hover:text-cyan-600 px-2 text-3xl mt-3" style={{transition: "color 0.4s"}}></i
        ></Link>
        {/* <Link
          to={{ pathname:"https://discord.com"}}
          target="_blank_"
          ><i className="fab fa-discord hover:text-violet-700 px-2 text-3xl mt-3" style={{transition: "color 0.4s"}}></i></Link> */}
      </div>
      <div className="flex justify-center mt-4">
      <p className="text-gray-600 text-xs sm:text-base pb-4 px-5 w-auto inline-block border-b-2">
              This website is made by Prerak Mehta on React and Tailwind CSS
      </p>
      </div>
    </footer>
    </div>
  )
}

export default Footer