import React, { useEffect, useState } from "react"
import { Switch, useLocation } from "react-router-dom"
import TopBarProgress from "react-topbar-progress-indicator"

const CustomSwitch = ({children}) => {

    const [progress, setProgress] = useState(false)
   const [prevLoc, setPrevLoc] = useState({})
   const location = useLocation()

   useEffect(() => {
    setPrevLoc(location.pathname);
    setProgress(true);
    window.scrollTo(0, 0); //Go to top on every page load
    if(location.pathname===prevLoc){
    setPrevLoc(''); //To fix same path infinite progress bar loading
    }
    }, [location]);

 useEffect(() => {
    setProgress(false)
 }, [prevLoc])

  return (
      <>
      {progress && <TopBarProgress />}
    <Switch>
        {children}
    </Switch>
      </>
  )
}

export default CustomSwitch