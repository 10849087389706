import React from 'react'
import {AiFillHtml5} from 'react-icons/ai'
import {DiCss3} from 'react-icons/di'
import {SiTailwindcss, SiJavascript, SiBootstrap, SiMongodb, SiJava} from 'react-icons/si'
import {FaReact, FaNodeJs} from 'react-icons/fa'

const Skills = () => {
  return (
    <div className='max-w-4xl mx-auto justify-center py-12' id='tech'>
        <p className="text-2xl text-center text-black sm:text4xl pt-4 font-bold">
            Tech I use
        </p>
        <div className="flex flex-wrap justify-center pt-2">
            <div className="flex flex-col w-40 p-10 m-6 overflow-hidden bg-white shadow-2xl rounded-xl sm:w-52">
                <AiFillHtml5 color='#e34c26' className='mx-auto text-4xl'/>
                <p className="mt-6 text-xl sm:text-2xl font-semibold text-center">HTML 5</p>
            </div>
            <div className="flex flex-col w-40 p-10 m-6 overflow-hidden bg-white shadow-2xl rounded-xl sm:w-52">
                <DiCss3 color='#2965f1' className='mx-auto text-4xl'/>
                <p className="mt-6 text-xl sm:text-2xl font-semibold text-center">CSS 3</p>
            </div>
            <div className="flex flex-col w-40 p-10 m-6 overflow-hidden bg-white shadow-2xl rounded-xl sm:w-52">
                <SiJavascript color='#F0DB4F' className='mx-auto text-4xl'/>
                <p className="mt-6 text-xl sm:text-2xl font-semibold text-center">JavaScript</p>
            </div>
            <div className="flex flex-col w-40 p-10 m-6 overflow-hidden bg-white shadow-2xl rounded-xl sm:w-52">
                <SiBootstrap color='#563d7c' className='mx-auto text-4xl'/>
                <p className="mt-6 text-xl sm:text-2xl font-semibold text-center">Bootstrap</p>
            </div>
            <div className="flex flex-col w-40 p-10 m-6 overflow-hidden bg-white shadow-2xl rounded-xl sm:w-52">
                <SiTailwindcss color='#66B2B2' className='mx-auto text-4xl'/>
                <p className="mt-6 text-xl sm:text-2xl font-semibold text-center">Tailwind css</p>
            </div>
            <div className="flex flex-col w-40 p-10 m-6 overflow-hidden bg-white shadow-2xl rounded-xl sm:w-52">
                <FaReact color='#61DBFB' className='mx-auto text-4xl'/>
                <p className="mt-6 text-xl sm:text-2xl font-semibold text-center">React</p>
            </div>
            <div className="flex flex-col w-40 p-10 m-6 overflow-hidden bg-white shadow-2xl rounded-xl sm:w-52">
                <FaNodeJs color='#68A063' className='mx-auto text-4xl'/>
                <p className="mt-6 text-xl sm:text-2xl font-semibold text-center">Nodejs</p>
            </div>
            <div className="flex flex-col w-40 p-10 m-6 overflow-hidden bg-white shadow-2xl rounded-xl sm:w-52">
                <SiJava color='#D0A384' className='mx-auto text-4xl'/>
                <p className="mt-6 text-xl sm:text-2xl font-semibold text-center">Java</p>
            </div>
            <div className="flex flex-col w-40 p-10 m-6 overflow-hidden bg-white shadow-2xl rounded-xl sm:w-52">
                <SiMongodb color='#3FA037' className='mx-auto text-4xl'/>
                <p className="mt-6 text-xl sm:text-2xl font-semibold text-center">MongoDB</p>
            </div>
        </div>
    </div>
  )
}

export default Skills