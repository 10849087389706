import './App.css';
import React, {useEffect} from 'react';
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import Navbar from './components/Navbar';
import Courses from './components/Courses';
import Home from './components/Home';
import Footer from './components/Footer';
import CustomSwitch from './components/CustomSwitch';
import Contact from './components/Contact';
import About from './components/About';
import Skills from './components/Skills';
import Aos from 'aos';
import 'aos/dist/aos.css'



function App() {

 useEffect(()=>{
   Aos.init({
     once: true,
   })
 })



  return (
    <>
    <Router>    
      <Navbar/>
      <CustomSwitch>

        <Route exact path="/">
          <div data-aos="fade-down" data-aos-duration='800'>
            <Home/>
          </div>
          <div data-aos='fade-up' data-aos-duration='800' data-aos-delay='400'>
            <About/>
          </div>
        <Skills/>
        </Route>

        <Route exact path="/courses">
        <Courses/>
        </Route>

        <Route exact path="/contact" >
        <Contact/>
        </Route>

        </CustomSwitch>
        <Footer/>
      </Router>
    </>
  );
}

export default App;
