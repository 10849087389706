import React from 'react'
import cpp from './cpp.png'
import webdev from './webdev.png'
import os from './os.png'
import course from './courses.png'
import javadsa from './javadsa.png'
import figma from './Figma.png'

import {
    Link
    } from "react-router-dom";
    

const Courses = () => {

    const toggle1 = ()=>{
        // alert("Finally!");

            if ( document.getElementById('accordion-collapse-body-1').classList.contains('hidden') ){
                document.getElementById('accordion-collapse-body-1').classList.remove('hidden');
                document.getElementById('svg1').classList.remove('rotate-180');
                document.getElementById('svg1').classList.add('rotate-180');
            }
            else{
                document.getElementById('accordion-collapse-body-1').classList.add('hidden');
                document.getElementById('svg1').classList.remove('rotate-180');
            }
        }

    const toggle2 = ()=>{
        // alert("Finally!");

            if ( document.getElementById('accordion-collapse-body-2').classList.contains('hidden') ){
                document.getElementById('accordion-collapse-body-2').classList.remove('hidden');
                document.getElementById('svg2').classList.remove('rotate-180');
                document.getElementById('svg2').classList.add('rotate-180');
            }
            else{
                document.getElementById('accordion-collapse-body-2').classList.add('hidden');
                document.getElementById('svg2').classList.remove('rotate-180');
            }
        }

    const toggle3 = ()=>{
        // alert("Finally!");

            if ( document.getElementById('accordion-collapse-body-3').classList.contains('hidden') ){
                document.getElementById('accordion-collapse-body-3').classList.remove('hidden');
                document.getElementById('svg3').classList.remove('rotate-180');
                document.getElementById('svg3').classList.add('rotate-180');
            }
            else{
                document.getElementById('accordion-collapse-body-3').classList.add('hidden');
                document.getElementById('svg3').classList.remove('rotate-180');
            }
        }

   

  return ( 
    <>
    <div className = 'flex bg-gray-200 h-96 container mx-auto' >
    <div className='flex items-center text-center lg:text-left px-8 md:px-12 lg:w-1/2'> 
    <div>
    <h2 className='text-3xl font-semibold text-gray-800 md:text-4xl'>
    Welcome to my Courses 
    </h2>
    <p className='mt-2 text-sm text-gray-500 md:text-base'> Confused on which course to take ? I have got you covered.Browse courses and find out the best course
    for you. It is my attempt to teach basics and those coding techniques to people in short time which took me ages to learn </p> 
    </div> 
    </div>

    <div className='hidden lg:block lg:w-1/2' style={{clipPath: "polygon(10% 0px, 100% 0%, 100% 100%, 0px 100%)"}}>
            <div className='h-full object-cover' style={{backgroundImage: `url(${course})`}}>
                <div className='h-full bg-black opacity-25'></div>
            </div>
        </div>
    </div>

    {/* Cards using Tailwind CSS */}
    <div className="container lg:py-10 mx-auto">

    <div className="flex justify-center align-middle flex-wrap mx-auto">
        
    {/* C++ Course card */}
    <div className="p-4 md:w-1/3 flex justify-center">
    <div className="max-w-sm rounded-2xl overflow-hidden shadow-lg">
    <Link to="#">
        <img className="w-full" src={cpp} alt="" />
    </Link>
    <div className="px-6 md:my-11 lg:my-0 lg:h-64 lg:py-4 xl:h-17">
        <div className="title-font text-lg font-medium text-gray-900 mb-3">
        <Link to="#">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">C++ MASTERY Course: Build Extraordinary Apps (Step-by-step)</h5>
        </Link>
        </div>
        <p className="text-gray-700 text-base">Effortlessly Build 10 C++ Apps Starting TODAY, WITHOUT ANY Coding Experience Step-by-Step.</p>

        
        <Link to={{ pathname:"https://www.udemy.com/course/the-complete-c-plus-plus-programming-course-build-10-computer-apps/?referralCode=16980AD6D524216CECEC"}} target="_blank_" className="my-3 inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-sky-700 rounded-lg hover:bg-sky-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Udemy
            <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
        </Link>
        
    </div>
    </div>
    </div>

    {/* Web Dev Course card */}
    <div className="p-4 md:w-1/3 flex justify-center">
    <div className="max-w-sm rounded-2xl overflow-hidden shadow-lg">
        
    <Link to="#">
        <img className="w-full" src={webdev} alt="" />
    </Link>
    <div className="px-6 md:my-11 lg:my-0 md:h-72 lg:h-64 lg:py-4 xl:h-52">
        <div className="title-font text-lg font-medium text-gray-900 mb-3">
        <Link to="#">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Full Stack Web Development MASTERY Course - Novice to Expert</h5>
        </Link>
        </div>
        <p className="text-gray-700 text-base">COMPLETE Beginner to Expert Guide to Making REAL Websites and Web Apps THIS YEAR With MERN Stack EVEN WITH Zero Experience</p>
        <Link to={{ pathname:"https://www.udemy.com/course/master-full-stack-web-development-novice-to-expert/?referralCode=6B914A1BA7FB646BCE5E"}} target="_blank_" className="my-3 inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-sky-700 rounded-lg hover:bg-sky-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Udemy
            <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
        </Link>
        <Link to={{pathname:"https://skl.sh/3O06pYj"}} target="_blank_" className="ml-12 my-3 inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-slate-500 rounded-lg hover:bg-slate-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            SkillShare
            <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </Link>
    </div>
    </div>
    </div>

    {/* OS Course card */}
    <div className="p-4 md:w-1/3 flex justify-center">
    <div className="max-w-sm rounded-2xl overflow-hidden shadow-lg">
        
    <Link to="#">
        <img className="w-full" src={os} alt="" />
    </Link>
    <div className="px-6 md:my-11 lg:my-0 md:h-72 lg:h-64 lg:py-4 xl:h-52">
        <div className="title-font text-lg font-medium text-gray-900 mb-3">
        <Link to="#">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Operating Systems MASTERY Course: Ace Interview Questions in 30 days</h5>
        </Link>
        </div>
        <p className="text-gray-700 text-base">COMPLETE Beginner to Expert Guide to Crack Technical Interview THIS YEAR With Operating Systems EVEN WITH Zero Knowledge</p>
        <Link to={{pathname:"https://www.udemy.com/course/operating-systems-course-ace-interview-questions-in-30-days/?referralCode=DE8BCFC8A55DE21483C3"}} target="_blank_" className="my-3 inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-sky-700 rounded-lg hover:bg-sky-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Udemy
            <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
        </Link>
        <Link to={{pathname:"https://skl.sh/3OTDXrx"}} target="_blank_" className="ml-12 my-3 inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-slate-500 rounded-lg hover:bg-slate-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            SkillShare
            <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </Link>
    </div>
    </div>
    </div>


    {/* Java DSA Course card */}
    <div className="p-4 md:w-1/3 flex justify-center">
    <div className="max-w-sm rounded-2xl overflow-hidden shadow-lg">
        
    <Link to="#">
        <img className="w-full" src={javadsa} alt="" />
    </Link>
    <div className="px-6 md:my-11 lg:my-0 md:h-72 lg:h-64 lg:py-4 xl:h-17">
        <div className="title-font text-lg font-medium text-gray-900 mb-3">
        <Link to="#">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Data Structures Algorithms in Java – SECRETS to Ace LeetCode</h5>
        </Link>
        </div>
        <p className="text-gray-700 text-base">COMPLETE Beginner to Expert Guide to Ace Coding Interview THIS YEAR with DSA in Java EVEN WITH Zero Experience.</p>
        <Link to={{pathname:"https://www.udemy.com/course/data-structures-algorithms-in-java-secrets-to-ace-leetcode/?referralCode=56C10CC91F22BB21C495"}} target="_blank_" className="my-3 inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-sky-700 rounded-lg hover:bg-sky-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Udemy
            <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
        </Link>
        {/* <Link to={{pathname:"https://skl.sh/3UIUOQJ"}} target="_blank_" className="ml-12 my-3 inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-slate-500 rounded-lg hover:bg-slate-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            SkillShare
            <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </Link> */}
    </div>
    </div>
    </div>

    {/* Fimga Course card */}
    <div className="p-4 md:w-1/3 flex justify-center">
    <div className="max-w-sm rounded-2xl overflow-hidden shadow-lg">
        
    <Link to="#">
        <img className="w-full" src={figma} alt="" />
    </Link>
    <div className="px-6 md:my-11 lg:my-0 md:h-72 lg:h-64 lg:py-4 xl:h-17">
        <div className="title-font text-lg font-medium text-gray-900 mb-3">
        <Link to="#">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Master the Secrets of Figma: A Complete Beginner's Guide on UI/UX Design</h5>
        </Link>
        </div>
        <p className="text-gray-700 text-base">Master Figma for Beginners: Design Stunning Websites and learn UI/UX in 30 Days or Less Without Prior Experience</p>
        <Link to={{pathname:"https://www.udemy.com/course/master-the-secrets-of-figma-a-complete-beginners-course/?referralCode=C3C879F3CD64BB930E04"}} target="_blank_" className="my-3 inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-sky-700 rounded-lg hover:bg-sky-900 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Udemy
            <svg className="ml-2 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
        </Link>
        <Link to={{pathname:"https://skl.sh/3IjN0lp"}} target="_blank_" className="ml-12 my-3 inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-slate-500 rounded-lg hover:bg-slate-700 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            SkillShare
            <svg className="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
        </Link>
    </div>
    </div>
    </div>
    
    
    </div>
    </div>

{/* FAQs */}

<div className="mb-8 text-center mx-3 text-5xl my-12">
Frequently Asked Questions

</div>

    <div className='md:grid-cols-1 md:justify-center md:items-center md:mx-72 md:my-10 m-5'>
    <div id="accordion-collapse" data-accordion="collapse">
<h2 id="accordion-collapse-heading-1">
<button type="button" onClick={toggle1}  className="flex justify-between items-center p-5 w-full font-medium text-left rounded-t-xl border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-800 bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-white" data-accordion-target="#accordion-collapse-body-1" aria-expanded="true" aria-controls="accordion-collapse-body-1">
<span>When does the course start and finish?</span>
<svg data-accordion-icon="" id='svg1' className="w-6 h-6 rotate-180 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
</button>
</h2>
<div id="accordion-collapse-body-1" className="" aria-labelledby="accordion-collapse-heading-1">
<div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
<p className="mb-2 text-gray-500 dark:text-gray-400"> The course starts now and never ends! It is a completely self-paced online course - you decide when you start and when you finish.</p>

</div>
</div>
<h2 id="accordion-collapse-heading-2">
<button type="button" onClick={toggle2}  className="flex justify-between items-center p-5 w-full font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-2" aria-expanded="false" aria-controls="accordion-collapse-body-2">
<span>Are the courses same on Udemy and Skillshare?</span>
<svg data-accordion-icon="" id='svg2' className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
</button>
</h2>
<div id="accordion-collapse-body-2" className="hidden" aria-labelledby="accordion-collapse-heading-2">
<div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700">
<p className="mb-2 text-gray-500 dark:text-gray-400">Yes! The courses are exactly same on both the platforms. With Udemy, once you purchase a course, you've got lifetime access to the material and can work through it at your own pace. With Skillshare you need to pay a monthly subscription to access all courses available on the platform.</p>
</div>
</div>
<h2 id="accordion-collapse-heading-3">
<button type="button" onClick={toggle3} className="flex justify-between items-center p-5 w-full font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" data-accordion-target="#accordion-collapse-body-3" aria-expanded="false" aria-controls="accordion-collapse-body-3">
<span>What are the course requirements?</span>
<svg data-accordion-icon="" id='svg3' className="w-6 h-6 shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
</button>
</h2>
<div id="accordion-collapse-body-3" className="hidden" aria-labelledby="accordion-collapse-heading-3">
<div className="p-5 border border-t-0 border-gray-200 dark:border-gray-700">
<p className="mb-2 text-gray-500 dark:text-gray-400">None! Just a willingness to learn and practice. The courses are aimed at complete beginners with no experience.</p>
</div>
</div>
</div>
</div>





    </>
  )
}

export default Courses